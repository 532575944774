import React from "react";
import {Link} from "gatsby";
import {buildArticleRoute, buildCategoryRoute} from "../util/routes";
import {GatsbyImage} from "gatsby-plugin-image";
import {convertDate} from "../util/time";
import {BlogTag} from "./BlogTag";
import {addDecimalToThousands, highlightSearchQuery} from "../util/stringUtil";
import clsx from "clsx";

const ArticleBlock = (props) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const article = props.article;
  const searchQuery = props.searchQuery;

    const articleCreated = new Date(article.created);

  return (
    <div className={clsx(props.className)} key={article.title}>
      <div className="relative">
        <Link to={buildArticleRoute(article.slug)}>
          {article.contentImage.image?.gatsbyImageData && (
            <GatsbyImage
              loading="lazy"
              className="object-center h-52 bg-center rounded-xl mb-4 hover:opacity-75"
              alt={article.contentImage.image?.alt ?? ""}
              image={article.contentImage.image.gatsbyImageData}
            />
          )}
        </Link>
          {articleCreated > oneMonthAgo && (
              <span className="inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-white bg-green-500 rounded-sm absolute right-2 top-2">
                  New
              </span>
          )}
        <span className="px-2 py-1 text-white bg-gray-900 dark:text-gray-300 dark:bg-gray-800 text-sm rounded absolute right-2 bottom-6 bg-opacity-60 border">
          {article.readTimeMinutes} min read
        </span>
      </div>

      <span className="flex gap-2">
        <Link
          to={buildCategoryRoute(article.category.label)}
          className="text-md font-medium text-blue-500 rounded-sm mb-0 dark:hover:text-gray-300 hover:text-green-500"
        >
          {highlightSearchQuery(article.category.label, searchQuery)}
        </Link>
      </span>
      <h3 className="mb-1 text-lg font-bold dark:text-gray-300 leading-tight">
        <Link
          className="hover:text-green-500"
          to={buildArticleRoute(article.slug)}
        >
          {highlightSearchQuery(article.title, searchQuery)}
        </Link>
      </h3>
      <div className="text-sm flex items-center dark:text-gray-200 gap-3 mb-2">
        <time className="inline-flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          {convertDate(article.created)}
        </time>

        <div className="inline-flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
            { addDecimalToThousands(article.views) } views
        </div>
      </div>

      <p
        className="mb-3 text-md text-gray-600 dark:text-gray-300"
        style={{ lineHeight: "25px" }}
      >
        {article.excerpt}
      </p>

      <div className="flex gap-1 flex-wrap">
        {article.tags.map((tag) => {
          return <BlogTag label={tag.label} key={tag.label} />;
        })}
      </div>
    </div>
  );
};

export { ArticleBlock };
