import * as React from 'react';
import {Anchor} from "../types";
import {Link} from "gatsby";
import {Period} from "./Period";
import {routes} from "../util/routes";

interface BreadcrumbProps {
    breadcrumbs?: Anchor[];
    activeLabel: string;
}

const Breadcrumb = (props: BreadcrumbProps) => {
    return <aside className="mx-auto py-5 border-b">
        <nav className="max-w-6xl 2xl:max-w-7xl mx-auto container 2xl:px-20 px-5">
            <ul className="flex text-sm mb-0">
                <li className="inline-flex items-center mb-0">
                    <Link to={routes.home} aria-label="Home">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-4 w-4 text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-500"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
                        </svg>
                    </Link>
                    <svg
                        className="h-5 w-auto text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </li>
                {props.breadcrumbs && props.breadcrumbs.map((breadcrumb) => {
                    return <li key={breadcrumb.label} className="inline-flex items-center dark:text-gray-300 mb-0">
                        {
                            breadcrumb.url ?
                                <Link className="hover:text-blue-500 dark:hover:text-blue-500"
                                      to={breadcrumb.url}>{breadcrumb.label}</Link> :
                                <span>{breadcrumb.label}</span>
                        }
                        <svg
                            className="h-5 w-auto text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </li>
                })}
                {
                    <li key={props.activeLabel} className="inline-flex items-center dark:text-gray-300 mb-0">
                        <span className="font-semibold">{props.activeLabel}<Period/></span>
                    </li>
                }
            </ul>
        </nav>
    </aside>
}

export {Breadcrumb};
