import React from "react";
import clsx from "clsx";
import {ArticleBlock} from "./ArticleBlock";
import {useGetArticleViews} from "../hooks/useGetArticleViews";

const SortPostsByYear = (props) => {
    let articlesByYearsMap = new Map();

    let slugs = [];

    for (let article of props.articles) {
        slugs.push(article.slug);
    }

    const { isLoading, views } = useGetArticleViews(slugs);

    props.articles.forEach((node) => {
        const year = new Date(node.created).getFullYear();

        if (!articlesByYearsMap.has(year)) {
            articlesByYearsMap.set(year, []);
        }

        articlesByYearsMap.get(year).push(node);
    });

    [...articlesByYearsMap.entries()].map((entry) => {
        const year = entry[0];
        const arr = entry[1];

        arr.sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
        });

        articlesByYearsMap.set(year, arr);
    });

    articlesByYearsMap = new Map(
        [...articlesByYearsMap.entries()].sort((a, b) => b[0] - a[0])
    );

    return (
        <section className="px-5 mx-auto max-w-7xl py-5">
            {Array.from(articlesByYearsMap.entries()).map((entry, index) => {
                const [key, value] = entry;
                return (
                    <section
                        className={clsx(
                            "pt-10 mx-auto max-w-6xl",
                            index === articlesByYearsMap.size - 1 && "pb-16"
                        )}
                        key={key}
                    >
                        <h2 className="pb-2 mb-12 text-2xl font-extrabold leading-tight border-b border-gray-200 md:text-4xl dark:text-gray-300">
                            {key}
                        </h2>

                        <nav className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10">
                            {value.map((article) => {

                                let view;

                                if (slugs.length == 1) {
                                    view = views;
                                } else {
                                    view = isLoading ? 0 : views[article.slug];
                                }

                                const extArticle = {
                                    ...article,
                                    category: {
                                        label: props.name
                                    },
                                    views: view
                                };

                                return (
                                    <ArticleBlock key={article.slug} article={extArticle} />
                                );
                            })}
                        </nav>
                    </section>
                );
            })}
        </section>
    )
}

export { SortPostsByYear }
