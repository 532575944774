import * as React from "react";
import {Breadcrumb} from "../components/Breadcrumb";
import {PageHeader} from "../components/PageHeader";
import {graphql} from "gatsby";
import {routes} from "../util/routes";
import {generateRandomColor} from "../util/colors";
import {Seo} from "../components/Seo";
import {SortPostsByYear} from "../components/sortPostsByYear";

const SingleTag = ({data, pageContext}) => {

    return (
        <>
            <Seo title={`${pageContext.name} - Tag`}/>
            <Breadcrumb
                breadcrumbs={[
                    {
                        label: "Blog",
                        url: routes.blog,
                    },
                    {
                        label: "Tag",
                    },
                ]}
                activeLabel={pageContext.name}
            />
            <PageHeader
                title="Tag"
                periodColor={generateRandomColor(3)}
                description={`Posts tagged: ${pageContext.name}`}
            />

            <SortPostsByYear articles={data.allGraphCmsArticle.nodes} name={pageContext.name} />
        </>
    );
};

export const query = graphql`
  query GetArticleTagQuery($id: String) {
    allGraphCmsArticle(filter: { tags: { elemMatch: { id: { eq: $id } } } }) {
      nodes {
        id
        slug
        title
        created
        contentImage {
          image {
            gatsbyImageData(placeholder: BLURRED, quality:70)
            alt
          }
        }
        tags {
          label
        }
      }
    }
  }
`;

export default SingleTag;
