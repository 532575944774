import * as React from "react";
import {Period} from "./Period";
import clsx from "clsx";

interface PageHeaderProps {
    title: string;
    description: string | React.ReactElement;
    periodColor?: string;
}

const PageHeader = (props: PageHeaderProps) => {
    const isString = props.description instanceof String;

    return <header className="py-10 text-center">
        <div className="max-w-4xl mx-auto">
            <h1 className={clsx("mb-0 text-lg font-semibold mb-2", props.periodColor)}>{props.title}</h1>
            {
                !isString ?
                    <p className="text-center text-2xl md:text-4xl font-bold dark:text-gray-300">{props.description}<Period/>
                    </p>
                    : props.description
            }
        </div>
    </header>
}

export {PageHeader};
